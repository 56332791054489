import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "emailjs-com";
import GetInTouch from "../images/get-touch.png";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    course: false,
    message: false,
  });

  const courseOptions = {
    1: "I B.D.S",   // Roman numeral for 1
    2: "II B.D.S",  // Roman numeral for 2
    3: "III B.D.S", // Roman numeral for 3
    4: "IV B.D.S", // Roman numeral for 4
    5: "Intern", // Roman numeral for Intern (IV)
  };

  // Update form state when user types
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate all form fields
  const validateForm = () => {
    const errors = {
      name: formData.name === "",
      email: formData.email === "",
      phone: formData.phone === "",
      course: formData.course === "",
      message: formData.message === "",
    };
    setFormErrors(errors);
    return !Object.values(errors).includes(true);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Only proceed if the form is valid
    if (!validateForm()) {
      return;
    }

    // Get the course name based on the selected course value
    const selectedCourse = courseOptions[formData.course] || formData.course;

    // Setup template parameters for EmailJS
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      course: selectedCourse, // Send the course name here
      message: formData.message,
    };

    // Send email using EmailJS
    emailjs
      .send(
        "cuspyd_prod", // Service ID
        "template_cuspyd", // Template ID
        templateParams,
        "d3yDqrf5sMYG95eZk" // API Public Key
      )
      .then(
        (response) => {
          console.log("Success:", response);
          alert("Message sent successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            course: "",
            message: "",
          }); // Reset form
        },
        (error) => {
          console.log("Error:", error);
          alert("Failed to send the message. Please try again.");
        }
      );
  };

  const position = [51.505, -0.09];

  // Check if all fields are filled to enable the Submit button
  const isSubmitDisabled = !(
    formData.name &&
    formData.email &&
    formData.phone &&
    formData.course &&
    formData.message
  );

  return (
    <div className="bg2 py-5 getin-touch" name="contactus">
      <Container>
        <div
          className="section-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <strong>
            Get in Touch <small></small>
          </strong>
        </div>
        <Row className="mt-5" data-aos="fade-up" data-aos-duration="2000">
          <Col md={5}>
          <img className="py-4" src={GetInTouch} alt="" />
          </Col>
          <Col md={7}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Enter Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={formErrors.name}
                  />
                  {formErrors.name && (
                    <Form.Control.Feedback type="invalid">
                      Full name is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col>
                  <Form.Control
                    size="lg"
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={formErrors.email}
                  />
                  {formErrors.email && (
                    <Form.Control.Feedback type="invalid">
                      Email is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col xs="4">
                      <Form.Select
                        size="lg"
                        aria-label="Country Code"
                        name="countryCode"
                        onChange={handleChange}
                      >
                        <option>+91</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        isInvalid={formErrors.phone}
                      />
                      {formErrors.phone && (
                        <Form.Control.Feedback type="invalid">
                          Phone number is required.
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Select
                    size="lg"
                    aria-label="Select Your Course"
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    isInvalid={formErrors.course}
                  >
                    <option>Select Your Course</option>
                    <option value="1">I B.D.S</option>
                    <option value="2">II B.D.S</option>
                    <option value="3">III B.D.S</option>
                    <option value="4">IV B.D.S</option>
                    <option value="5">Intern</option>
                  </Form.Select>
                  {formErrors.course && (
                    <Form.Control.Feedback type="invalid">
                      Course selection is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder="Leave A Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    isInvalid={formErrors.message}
                  />
                  {formErrors.message && (
                    <Form.Control.Feedback type="invalid">
                      Message is required.
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-end">
                  <Button
                    className="btn-primary-1"
                    size="lg"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUs;
